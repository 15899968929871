"use client"

import { useState } from 'react'
import Link from 'next/link'


import { Sheet, SheetContent, SheetTrigger, SheetClose } from "@/components/ui/sheet"
import { Button } from "@/components/ui/Button"
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"
import LoginForm from '@/components/sheets/LoginForm'
import RegisterForm from '@/components/sheets/RegisterForm'
import { useAuthSheet } from '@/context/AuthSheetContext'
import { useRouter } from 'next/navigation'
import { MemberProfile } from '@/types/user.types'

import { Menu as HamburgerIcon, LogIn, UserPlus } from 'lucide-react'

interface HeaderClientProps {
  isLoggedIn: boolean;
  userData: MemberProfile | null;
  logoutAction: (formData: FormData) => Promise<never>;
  siteName: string;
}

export function HeaderClient({ isLoggedIn, userData, logoutAction, siteName }: HeaderClientProps) {
  const [authMode, setAuthMode] = useState<'login' | 'register'>('login')
  const { isSheetOpen, setIsSheetOpen, avatarUrl, userName, userEmail } = useAuthSheet()
  const router = useRouter()

  const handleAuthModeChange = (mode: 'login' | 'register', e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setAuthMode(mode)
  }

  const handleSheetClose = () => {
    setIsSheetOpen(false)
  }

  const handleLogout = async (formData: FormData) => {
    await logoutAction(formData)
    handleSheetClose()
    router.refresh()
    window.location.reload()
  }

  // const SheetHeader = () => (
  //   <div className="flex flex-col space-y-4 mb-4">
  //     <SheetClose asChild>
  //       <Button variant="secondary" className="w-full" onClick={handleSheetClose}>
  //         Close
  //       </Button>
  //     </SheetClose>
  //   </div>
  // )

  const ProfileContent = ({ className = "" }) => (
    <div className={`grid items-start gap-4 ${className}`}>
      <div className="flex flex-col space-y-2">
        <div className="flex items-center space-x-4">
          <Avatar className="h-12 w-12">
            <AvatarImage src={avatarUrl || userData?.avatar} alt={userData?.name || "@user"} />
            <AvatarFallback>{(userName || userData?.name || "U")[0].toUpperCase()}</AvatarFallback>
          </Avatar>
          <div>
            <h4 className="font-medium leading-none text-[var(--input-text-color)]">{ userName || userData?.name || "User"}</h4>
            <p className="text-sm text-[var(--text-color)] opacity-70">{userEmail || userData?.email || "user@example.com"}</p>
          </div>
        </div>
      </div>
      <div className="flex flex-col space-y-4">
        <SheetClose asChild>
          <Link href="/account" className="ext-lg font-semibold text-[var(--text-color)] hover:text-[var(--input-text-color)]">
            Account Settings
          </Link>
        </SheetClose>
        <form action={handleLogout}>
          <SheetClose asChild>
            <button
              type="submit"
              className="ext-lg font-semibold text-[var(--text-color)] hover:text-[var(--input-text-color)] "
            >
              Log out
            </button>
          </SheetClose>
        </form>
      </div>
    </div>
  )

  const AuthContent = () => (
    <div className="flex flex-col space-y-6">
      <div className="grid grid-cols-2 gap-4">
        <button
          type="button"
          onClick={(e) => handleAuthModeChange("login", e)}
          className={`flex flex-col items-center space-y-2 p-4 rounded-lg transition-colors
            ${authMode === "login" 
              ? "bg-[var(--form-bg-color)] text-[var(--input-text-color)]" 
              : "text-[var(--text-primary-alpha)] hover:text-[var(--text-primary)] hover:bg-[var(--form-bg-color)]"}`}
        >
          <LogIn className="h-6 w-6" />
          <div className="text-center">
            <div className="font-medium">Login</div>
            <div className="text-xs">Have an account?</div>
          </div>
        </button>

        <button
          type="button"
          onClick={(e) => handleAuthModeChange("register", e)}
          className={`flex flex-col items-center space-y-2 p-4 rounded-lg transition-colors
            ${authMode === "register" 
              ? "bg-[var(--form-bg-color)] text-[var(--input-text-color)]" 
              : "text-[var(--text-primary-alpha)] hover:text-[var(--text-primary)] hover:bg-[var(--form-bg-color)]"}`}
        >
          <UserPlus className="h-6 w-6" />
          <div className="text-center">
            <div className="font-medium">Register</div>
            <div className="text-xs">No account?</div>
          </div>
        </button>
      </div>

      <div className="pt-4 border-t border-[var(--input-border-color)]">
        {authMode === "login" ? (
          <LoginForm onToggleForm={() => setAuthMode("register")} />
        ) : (
          <RegisterForm onToggleForm={() => setAuthMode("login")} />
        )}
      </div>
    </div>
  )

  return (
    <header className="sticky top-0 z-50 w-full border-b border-[var(--input-border-color)] backdrop-blur supports-[backdrop-filter]:bg-transparent">
      <div className="container flex h-14 max-w-full px-4">
        <div className="flex items-center">
          <Sheet>
            <SheetTrigger asChild>
              <Button variant="ghost" className="text-[var(--text-color)] hover:text-[var(--input-text-color)] bg-transparent hover:bg-transparent focus:ring-0 flex items-center justify-center">
                <HamburgerIcon style={{ width: '2.5rem', height: '2.5rem' }} />
                <span className="sr-only">Toggle Menu</span>
              </Button>
            </SheetTrigger>
            <SheetContent 
              side="left" 
              title=" "
              className="[&>button]:hidden w-[300px] sm:w-[400px] Sheet-Background border border-[var(--input-border-color)] backdrop-blur supports-[backdrop-filter]:bg-background/80"
            >
              <div className="flex flex-col space-y-4 mb-4">
                <SheetClose asChild>
                  <Button variant="secondary" className="w-full bg-[var(--secondary-color)] text-[var(--input-text-color)] hover:text-[var(--input-text-color)] hover:bg-[var(--secondary-color)] hover:opacity-80" onClick={handleSheetClose}>
                    Close
                  </Button>
                </SheetClose>
              </div>
              <nav className="flex flex-col space-y-4">
                <SheetClose asChild>
                  <Link href="/" className="text-lg font-semibold text-[var(--text-color)] hover:text-[var(--input-text-color)]">
                    Home
                  </Link>
                </SheetClose>
                <SheetClose asChild>
                  <Link href="/about" className="text-lg font-semibold text-[var(--text-color)] hover:text-[var(--input-text-color)]">
                    About
                  </Link>
                </SheetClose>
                <SheetClose asChild>
                  <Link href="/legal" className="text-lg font-semibold text-[var(--text-color)] hover:text-[var(--input-text-color)]">
                    Legal
                  </Link>
                </SheetClose>
                <SheetClose asChild>
                  <Link href="/contact" className="text-lg font-semibold text-[var(--text-color)] hover:text-[var(--input-text-color)]">
                    Contact
                  </Link>
                </SheetClose>
              </nav>
            </SheetContent>
          </Sheet>
        </div>

        <div className="flex-1 flex justify-center items-center">
          <Link href="/" className="flex items-center space-x-2">
            <img src="/images/logo192.png" alt={siteName} className="h-12 w-12" />
            <span className="hidden font-bold sm:inline-block text-2xl text-[var(--text-color)]">{siteName}</span>
          </Link>
        </div>

        <div className="flex items-center justify-end">
          {!isLoggedIn ? (
            <Sheet open={isSheetOpen} onOpenChange={setIsSheetOpen}>
              <SheetTrigger asChild>
                <Button 
                  variant="ghost" 
                  className="relative h-12 w-12 rounded-full bg-transparent hover:bg-transparent"
                >
                  <Avatar className="h-12 w-12">
                    <AvatarFallback className="bg-transparent">
                      <LogIn 
                        style={{ width: '2.5rem', height: '2.5rem' }} 
                        className="text-[var(--text-primary)] transition-colors hover:text-[var(--input-text-color)]" 
                      />
                    </AvatarFallback>
                  </Avatar>
                </Button>
              </SheetTrigger>
              <SheetContent 
                side="right"
                title=" "
                className="[&>button]:hidden w-[300px] sm:w-[400px] sheet-backdrop border-[var(--input-border-color)]"
              >
                <div className="flex flex-col space-y-4 mb-4">
                  <SheetClose asChild>
                    <Button 
                      variant="secondary" 
                      className="w-full bg-[var(--secondary-color)] text-[var(--input-text-color)] hover:text-[var(--input-text-color)] hover:bg-[var(--secondary-color)] hover:opacity-80" 
                      onClick={handleSheetClose}
                    >
                      Close
                    </Button>
                  </SheetClose>
                </div>
                <AuthContent />
              </SheetContent>
            </Sheet>
          ) : (
            <Sheet>
              <SheetTrigger asChild>
                <Button variant="ghost" className="relative h-12 w-12 rounded-full bg-transparent hover:bg-transparent">
                  <Avatar className="h-10 w-10">
                    <AvatarImage src={avatarUrl || userData?.avatar} alt={userData?.name || "@user"} />
                    <AvatarFallback>{(userName || userData?.name || "U")[0].toUpperCase()}</AvatarFallback>
                  </Avatar>
                </Button>
              </SheetTrigger>
              <SheetContent 
                side="right"
                title=" "
                className="[&>button]:hidden w-[300px] sm:w-[400px] Sheet-Background border border-[var(--input-border-color)] backdrop-blur supports-[backdrop-filter]:bg-background/80"
              >
                <div className="flex flex-col space-y-4 mb-4">
                  <SheetClose asChild>
                    <Button variant="secondary" className="w-full bg-[var(--secondary-color)] text-[var(--input-text-color)] hover:text-[var(--input-text-color)] hover:bg-[var(--secondary-color)] hover:opacity-80" onClick={handleSheetClose}>
                      Close
                    </Button>
                  </SheetClose>
                </div>
                <div>
                  <ProfileContent className="text-[var(--text-color)]" />
                </div>
              </SheetContent>
            </Sheet>
          )}
        </div>
      </div>
    </header>
  )
}
