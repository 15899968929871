'use client';

import React, { FormEvent, useState } from 'react';
import axios from 'axios';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/Button';
import { Label } from '@/components/ui/label';
import { toast } from "sonner";

interface RegisterFormProps {
  onToggleForm: () => void;
  onSuccess?: () => void;
}

const Register: React.FC<RegisterFormProps> = ({ onToggleForm, onSuccess }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: ''
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData(prev => ({
      ...prev,
      [e.target.id]: e.target.value
    }));
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    if (formData.password !== formData.confirmPassword) {
      toast.error('Passwords do not match');
      return;
    }

    if (formData.password.length < 6) {
      toast.error('Password must be at least 6 characters');
      return;
    }

    setIsLoading(true);
    try {
      const response = await axios.post('/api/register', {
        username: formData.name,
        email: formData.email,
        password: formData.password,
      });

      if (response.status === 200) {
        toast.success('Registration successful!');
        setFormData({ name: '', email: '', password: '', confirmPassword: '' });
        onSuccess?.();
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const message = error.response?.data?.message || 'Registration failed';
        toast.error(message);
      } else {
        toast.error('An unexpected error occurred');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="space-y-4">
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="name" className="text-[var(--text-primary)]">Name</Label>
          <Input
            id="name"
            type="text"
            value={formData.name}
            onChange={handleChange}
            placeholder="Enter your name"
            className="w-full bg-[var(--form-bg-color)] border-[var(--input-border-color)] text-[var(--input-text-color)] placeholder:text-[var(--text-primary-alpha)]"
            required
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="email" className="text-[var(--text-primary)]">Email</Label>
          <Input
            id="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Enter your email"
            className="w-full bg-[var(--form-bg-color)] border-[var(--input-border-color)] text-[var(--input-text-color)] placeholder:text-[var(--text-primary-alpha)]"
            required
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="password" className="text-[var(--text-primary)]">Password</Label>
          <Input
            id="password"
            type="password"
            value={formData.password}
            onChange={handleChange}
            placeholder="Choose a password"
            className="w-full bg-[var(--form-bg-color)] border-[var(--input-border-color)] text-[var(--input-text-color)] placeholder:text-[var(--text-primary-alpha)]"
            required
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="confirmPassword" className="text-[var(--text-primary)]">Confirm Password</Label>
          <Input
            id="confirmPassword"
            type="password"
            value={formData.confirmPassword}
            onChange={handleChange}
            placeholder="Confirm your password"
            className="w-full bg-[var(--form-bg-color)] border-[var(--input-border-color)] text-[var(--input-text-color)] placeholder:text-[var(--text-primary-alpha)]"
            required
          />
        </div>

        <Button 
          type="submit" 
          className="w-full bg-[var(--secondary-color)] text-[var(--input-text-color)] hover:text-[var(--input-text-color)] hover:bg-[var(--secondary-color)] hover:opacity-80"
          disabled={isLoading}
        >
          {isLoading ? "Creating account..." : "Create Account"}
        </Button>

        <Button
          type="button"
          variant="ghost"
          className="w-full text-[var(--text-primary)] hover:text-[var(--input-text-color)]"
          onClick={onToggleForm}
        >
          Already have an account? Login
        </Button>
      </form>
    </div>
  );
};

export default Register;
