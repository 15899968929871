"use client";

import { FormEvent, useState } from 'react';
import { useRouter } from 'next/navigation';
import { toast } from 'sonner';
import { Button } from '@/components/ui/Button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Card } from '@/components/ui/card';
import { handleLogin } from '@/app/auth/actions';

interface LoginFormProps {
  onToggleForm: () => void;
  onSuccess?: () => void;
}

export default function LoginForm({ onToggleForm, onSuccess }: LoginFormProps) {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const formData = new FormData(event.currentTarget);
      const result = await handleLogin(formData);

      // Show success toast first
      toast.success('Successfully logged in!');

      // Close the auth sheet if provided
      onSuccess?.();

      // Handle redirect or refresh
      if (result.redirectTo) {
        router.push(result.redirectTo);
      } else {
        router.refresh();
      }
    } catch (error) {
      if (error instanceof Error) {
        switch (error.message) {
          case 'User not found.':
            toast.error('No account found with this email. Please register first.');
            break;
          case 'Invalid Password!':
            toast.error('Invalid password. Please try again.');
            break;
          case 'Email and password are required.':
            toast.error('Please enter both email and password.');
            break;
          default:
            toast.error('Login failed. Please try again.');
        }
      } else {
        toast.error('An unexpected error occurred. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card className="w-full max-w-md p-6 space-y-4">
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="email" className="text-[var(--text-primary)]">Email</Label>
            <Input
              id="email"
              name="email"
              type="email"
              placeholder="Enter your email"
              className="w-full bg-[var(--form-bg-color)] border-[var(--input-border-color)] text-[var(--input-text-color)] placeholder:text-[var(--text-primary-alpha)]"
              required
              disabled={isLoading}
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="password" className="text-[var(--text-primary)]">Password</Label>
            <Input
              id="password"
              name="password"
              type="password"
              placeholder="Enter your password"
              className="w-full bg-[var(--form-bg-color)] border-[var(--input-border-color)] text-[var(--input-text-color)] placeholder:text-[var(--text-primary-alpha)]"
              required
              disabled={isLoading}
            />
          </div>
          <Button
            type="submit"
            className="w-full bg-[var(--secondary-color)] text-[var(--input-text-color)] hover:text-[var(--input-text-color)] hover:bg-[var(--secondary-color)] hover:opacity-80"
            disabled={isLoading}
          >
            {isLoading ? 'Logging in...' : 'Login'}
          </Button>
        </div>
      </form>
      <div className="text-center">
        <button
          onClick={onToggleForm}
          className="text-sm text-gray-500 hover:text-gray-700"
        >
          Don&apos;t have an account? Register
        </button>
      </div>
    </Card>
  );
}
