"use client"

import Link from 'next/link'
import { Home, Users, Gamepad2 } from 'lucide-react'
import { useAuthSheet } from '@/context/AuthSheetContext'
import { useRouter } from 'next/navigation'

interface FooterClientProps {
  isLoggedIn?: boolean;
}

export function FooterClient({ isLoggedIn = false }: FooterClientProps) {
  const { setIsSheetOpen } = useAuthSheet()
  const router = useRouter()

  const handleRoomsClick = (e: React.MouseEvent) => {
    if (!isLoggedIn) {
      e.preventDefault()
      setIsSheetOpen(true)
    } else {
      router.push('/dashboard')
    }
  }

  return (
    <footer id="site-footer" className="sticky bottom-0 z-50 w-full border-t border-primary backdrop-blur supports-[backdrop-filter]:bg-transparent">
      <div className="container flex h-14 max-w-full px-4">
        <div className="flex-1 flex items-center justify-start">
          <button 
            onClick={handleRoomsClick}
            className="flex flex-col items-center text-primary-foreground/70 hover:text-primary-foreground bg-transparent border-none"
          >
            <Users className="h-6 w-6" />
            <span className="text-xs">Rooms</span>
          </button>
        </div>

        <div className="flex-1 flex items-center justify-center">
          <Link 
            href="/" 
            className="flex flex-col items-center text-primary-foreground/70 hover:text-primary-foreground"
          >
            <Home className="h-6 w-6" />
            <span className="text-xs">Home</span>
          </Link>
        </div>

        <div className="flex-1 flex items-center justify-end">
          <Link 
            href="/games" 
            className="flex flex-col items-center text-primary-foreground/70 hover:text-primary-foreground"
          >
            <Gamepad2 className="h-6 w-6" />
            <span className="text-xs">Games</span>
          </Link>
        </div>
      </div>
    </footer>
  )
}
