"use client";

import { useResponsive } from "@/components/layout/ResponsiveLayout";
import { Carousel, CarouselContent, CarouselItem, CarouselPrevious, CarouselNext } from "@/components/ui/carousel";
import FeatureBox from "@/components/ui/FeatureBox";
import JoinRoomForm from "@/components/home/JoinRoomForm";
import JoinRoomFormMobile from "@/components/home/JoinRoomFormMobile";

interface Feature {
  icon: string;
  title: string;
  description: string;
}

interface HomeContentProps {
  features: Feature[];
}

export default function HomeContent({ features }: HomeContentProps) {
  const { isMobile, isLandscape } = useResponsive();

  return (
    <div className="App bg-grid-pattern">
      <div className={`landing-page flex flex-col items-center ${isMobile && !isLandscape ? 'h-[calc(100vh-180px)] overflow-y-auto' : ''}`}>
        <main className="flex-1 w-full max-w-6xl px-4">
          <section className="hero flex flex-col items-center justify-center">
            <h2 
              className={`text-center text-4xl md:text-6xl font-bold mb-8 text-cyan-200 
                         ${isLandscape ? 'landscape-text' : ''}
                         [text-shadow:_0_0_20px_rgb(6_182_212_/_50%)]
                         tracking-tight leading-tight`}
            >
              Connect, Play, and Enjoy<br />Being Together
            </h2>

            {isMobile ? <JoinRoomFormMobile /> : <JoinRoomForm />}
          </section>

          <section className="features mt-8">
            {isMobile && !isLandscape ? (
              <div className="mobile-features flex justify-center">
                <Carousel className="w-full max-w-xs">
                  <CarouselContent>
                    {features.map((feature, index) => (
                      <CarouselItem key={index}>
                        <div className="p-1">
                          <FeatureBox {...feature} />
                        </div>
                      </CarouselItem>
                    ))}
                  </CarouselContent>
                  <CarouselPrevious className="carousel-prev" />
                  <CarouselNext className="carousel-next" />
                </Carousel>
              </div>
            ) : !isMobile && (
              <div className="features-grid grid grid-cols-3 gap-6 w-full">
                {features.map((feature, index) => (
                  <FeatureBox key={index} {...feature} />
                ))}
              </div>
            )}
          </section>
        </main>
      </div>
    </div>
  );
}
